import { AnimatePresence, motion } from 'framer-motion';
import { ReactNode } from 'react';
import classNames from '$lib/classNames';
import Spinner from './Spinner';

interface ButtonProps {
  children?: ReactNode;
  className?: string;
  variant?: 'primary' | 'secondary';
  type?: 'button' | 'submit';
  size?: 'xs' | 's' | 'm' | 'l' | 'xl';
  onClick?(): void;
  loading?: boolean;
  form?: string;
  disabled?: boolean;
}

export default function Button({
  children,
  className,
  variant = 'primary',
  type = 'button',
  size = 'm',
  onClick,
  loading,
  form,
  disabled,
}: ButtonProps) {
  return (
    <button
      type={type}
      className={classNames(
        'relative inline-flex items-center border border-transparent font-medium shadow-sm',
        variant === 'primary'
          ? 'bg-green-900 text-white hover:bg-green-900 focus:outline-none focus:ring-2 focus:ring-green-700 focus:ring-offset-2'
          : 'bg-green-100 text-green-900 hover:bg-green-200 focus:outline-none focus:ring-2 focus:ring-green-700 focus:ring-offset-2',
        size === 'xs' && 'rounded px-2.5 py-1.5 text-xs',
        size === 's' && 'rounded-md px-3 py-2 text-sm leading-4',
        size === 'm' && 'rounded-md px-4 py-2 text-sm',
        size === 'l' && 'rounded-md px-4 py-2 text-base',
        size === 'xl' && 'rounded-md px-6 py-3 text-base',
        className
      )}
      onClick={onClick}
      form={form}
      disabled={disabled || loading}
    >
      {children}
      {loading && (
        <motion.div
          className="absolute inset-0 flex h-full w-full items-center justify-center rounded-md"
          style={{
            backgroundColor: 'inherit',
          }}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
        >
          <Spinner className="h-4 w-4" />
        </motion.div>
      )}
    </button>
  );
}
