import { useRouter } from 'next/router';
import rosetta from 'rosetta';
import { sify } from 'chinese-conv';

const tables = {
  en: {
    step: (obj: { step: number }) => `Step ${obj.step}`,
    signIn: 'Sign In',
    signUp: 'Sign Up',
    email: 'Email',
    password: 'Password',
    confirm: 'Confirm',
    forgetPassword: 'Forget Password',
    backToSignIn: 'Back to Sign In',
    resetPassword: 'Reset Password',
    home: 'Home',
    seller: 'Seller',
    proSeller: 'Pro Seller',
    members: 'Members',
    products: 'Products',
    items: 'Items',
    pushNotifications: 'Push Notifications',
    proMembersRequests: 'Pro Member Requests',
    messages: 'Messages',
    users: 'Users',
    business: 'Business',
    signOut: 'Sign Out',
    create: 'Create',
    role: 'Role',
    name: 'Name',
    mobile: 'Mobile',
    noRecords: 'No Records',
    brands: 'Brands',
    collections: 'Collections',
    itemTypes: 'Item Types',
    watch: 'Watch',
    accessory: 'Accessory',
    bag: 'Bag',
    diamond: 'Diamond',
    back: 'Back',
    nameEn: 'Name (Eng)',
    nameChi: 'Name (Chi)',
    createCollection: 'Create Collection',
    edit: 'Edit',
    cancel: 'Cancel',
    noCollections: 'No Collections',
    remove: 'Remove',
    basicInfo: 'Basic Information',
    memberType: 'Member Type',
    country: 'Country',
    sellingItems: 'Selling Items',
    viewAll: 'View All',
    banner: 'Banner',
    landing: 'Landing',
    createBanner: 'Create Banner',
    action: 'Action',
    goToItemType: 'Go to item type',
    goToItem: 'Go to item',
    goToNews: 'Go to news',
    goToExternal: 'Go to external',
    fileUploadPlaceholder:
      'Drag & Drop your files or <span class="filepond--label-action">Browse</span>',
    news: 'News',
    externalLink: 'External Link',
    createUser: 'Create User',
    confirmToRemove: 'Confirm to remove?',
    title: 'Title',
    createdAt: 'Created At',
    updatedAt: 'Updated At',
    createNews: 'Create News',
    content: 'Content',
    saved: 'Saved!',
    saveInstruction: 'Press CMD + S to save',
    image: 'Image',
    signInIsNeeded: 'Sign In is needed',
    goToSignIn: 'Go to sign in',
    signInWithEmail: 'Sign In with Email',
    signInWithMobile: 'Sign In with Mobile',
    signInMessage: 'Sign in to explore the world of luxury',
    signUpHint: `We will call or text to confirm your phone number. This may incur general SMS and data transfer charges.`,
    continue: 'Continue',
    selectYourMembershipType: 'Select your membership type',
    selectYourMembershipTypeHint:
      'Select your membership type according to your needs',
    inputYourData: 'Input your data',
    confirmPassword: 'Confirm Password',
    inputYourDataHint: 'By clicking Continue, you agree to our ',
    tAndC: 'Terms and Conditions',
    and: 'and',
    privacyPolicy: 'Privacy Policy',
    agreeAndContinue: 'Agree and Continue',
    error: 'Error',
    resendOtp: 'Resend Verification Code',
    verify: 'Verify',
    welcomeMsg: 'Welcome to Amoeba!',
    signUpSuccessMsg: 'Sign Up Successfully! Explore the world of luxury now!',
    noAccountYet: 'No Account Yet?',
    chooseTheItemType: 'Choose the item type',
    nextStep: 'Next',
    selectBrand: 'Select Brand',
    loadingMore: 'Loading More...',
    loadedAll: 'Loaded All!',
    publish: 'Publish',
    saveAsDraft: 'Save As Draft',
    watchInfo: 'Watch Information',
    bagInfo: 'Bag Information',
    diamondInfo: 'Diamond Information',
    accessoryInfo: 'Accessory Information',
    itemName: 'Item Name',
    addTag: 'Add Tag',
    uploadImage: 'Upload Image',
    description: 'Description',
    itemDescription: 'Item Description',
    createNewTag: 'Create New Tag',
    yes: 'Yes',
    no: 'No',
    price: 'Price',
    confirmToRemoveBanner: 'Confirm to remove banner?',
    itemsTypeData: 'Items Type Data',
    adNo: 'Ads No',
    status: 'Status',
    tags: 'Tags',
    views: 'Views',
    watchBand: 'Watch Band',
    watchAccessory: 'Watch Accessory',
    watchBandCellNo: 'Number of band cells',
    watchBandMaterial: 'Band material',
    watchBandTexture: 'Band texture',
    watchCertLocation: 'Certificate location',
    watchManufactureYear: 'Manufacture year',
    watchMovement: 'Watch Movement',
    watchStatus: 'Watch Status',
    bagColor: 'Bag Color',
    bagSize: 'Bag Size',
    bagStatus: 'Bag Status',
    hasBagCert: 'Has bag certificate',
    diamondCategory: 'Diamond Category',
    diamondPurity: 'Diamond Purity',
    diamondShape: 'Diamond Shape',
    diamondStatus: 'Diamond Status',
    hasDiamondCert: 'Has diamond certificate',
    accessoryStatus: 'Accessory Status',
    accessoryType: 'Accessory Type',
    all: 'All',
    hotBrands: 'Popular Brands',
    hotWatch: 'Popular Watch',
    hotBag: 'Popular Bag',
    hotDiamond: 'Popular Diamond',
    allHotItems: 'All Popular Items',
    latestWatch: 'Latest Watch',
    latestBag: 'Latest Bag',
    latestDiamond: 'Latest Diamond',
    allItems: 'All Items',
    allAccessory: 'All Accessory',
    yourFav: 'Your Favorite',
    apply: 'Apply',
    search: 'Search',
    sell: 'Sell',
    comments: 'Comments',
    filter: 'Filter',
    sortBy: 'Sort By',
    publicData: 'Public Data',
    privateData: 'Private Data',
    contactEmail: 'Contact Email',
    contactMobile: 'Contact Mobile',
    setting: 'Setting',
    account: 'Account',
    changePassword: 'Change Password',
    preference: 'Preference',
    notiSetting: 'Notification Setting',
    about: 'About',
    contactUs: 'Contact Us',
    language: 'Language',
    tc: 'Traditional Chinese',
    sc: 'Simplified Chinese',
    en: 'English',
    editProfile: 'Edit Profile',
    finish: 'Finish',
    surname: 'Surname',
    givenName: 'Given Name',
    bid: 'Bid',
    buy: 'Buy',
    allConversation: 'All Conversation',
    contactBuyer: 'Contact Buyer',
    bidHint: 'After you bid, seller will be notified by system.',
    accept: 'Accept',
    reject: 'Reject',
    accepted: 'Accepted',
    rejected: 'Rejected',
    bided: 'Bided',
    closeConversation: 'Close Conversation',
    viewUserProfile: 'View User Profile',
    closed: 'Closed',
    acceptBid: 'Accept Bid',
    detail: 'Detail',
    comment: 'Comment',
    commented: 'Commented',
    commentDetail: 'Comment Detail',
    itemStatus: 'Item Status',
    responseSpeed: 'Response Speed',
    serviceAttitude: 'Service Attitude',
    trendingProcess: 'Trending Process',
    sold: 'Sold',
    onHold: 'On Hold',
    trend: 'Trend',
    createTrend: 'Create Trend',
    year: 'Year',
    month: 'Month',
    basic: 'Basic',
    greeting: 'Welcome to Amoeba!',
    askToJoinMsg:
      'To explore the world of luxury, you will need to sign in/sign up to continue.',
    valueTrend: 'Value Trend',
    sellerInformation: 'Seller Information',
    emptyTrend: 'No related data found',
    becomeVerifiedPro: 'Verify as PRO',
    uploadProProveHint: 'Upload your BR to verify',
    uploadProProveDoneMsg: 'Upload BR is done',
    history: 'History',
    sms: 'SMS',
    optIn: 'Agree to accept notification',
    favorite: 'Favorite',
    allNews: 'All news',
    sellAnItem: 'Sell an item',
    hotItems: 'Popular items',
    latestItems: 'Latest items',
    allVariants: 'All items',
    latestNews: 'Latest news',
    noFavorite: 'No favorite yet',
    resetPw: 'Reset Password',
    resetPwHint: 'Enter your email/mobile to reset password',
    searchOnAmoeba: 'Search on Amoeba 28',
    signUpWithEmail: 'Sign up with email',
    signUpWithMobile: 'Sign up with mobile',
    selectYourMembershipTypeHintSeller: 'I collect for interest',
    selectYourMembershipTypeHintProSeller: 'I work for collections',
    waitForVerify: 'Wait for verification',
    conversations: 'Conversations',
    roleStr: {
      member: 'Private Seller',
      pro: 'Pro Seller',
      'pro-verified': 'Verified Pro Seller',
    },
    pagination: {
      showing: 'Showing',
      to: 'to',
      of: 'of',
      results: 'results',
      previous: 'Previous',
      next: 'Next',
    },
    errors: {
      EMAIL_IS_REQUIRED: 'Email is required.',
      EMAIL_VALIDATE_ERROR: "It's not a valid email.",
      PASSWORD_IS_REQUIRED:
        'Password is required. Must be contain a-z, A-Z, and 0-9',
      PAGE_NUM_ERROR: 'Page number must be larger than 0',
      ORDER_BY_ERROR: 'You cannot order by this field',
      WHERE_ERROR: 'Select condition is wrong',
      THIS_FIELD_IS_REQUIRED: 'This field is required.',
      MOBILE_IS_USED: 'This mobile is used.',
      EMAIL_IS_USED: 'This email is used.',
      UNABLE_TO_SEND_CODE: 'Unable to send verification code.',
      FAIL_TO_VERIFY_CODE: 'Fail to verify code.',
      NOT_EXISTS_USER: 'User not exists.',
      PW_NOT_CORRECT: 'Password is not correct.',
      TAG_ALREADY_EXISTS: 'Tag already exists.',
      MOBILE_VALIDATE_ERROR: "It's not a valid mobile.",
    },
    alert: {
      signInSuccess: 'Sign in successfully',
      signInFail: 'Sign in fail. Please try again.',
      updateSuccessfully: 'Updated!',
      createUserFail: 'Fail to create user',
      removeSuccess: 'Success to remove',
      removeFail: 'Fail to remove. Please try again.',
      passwordNotMatch: 'Password not match',
      signUpFail: 'Sign up fail. Please try again.',
      createdItemSuccess: 'Created item successfully',
      createdItemFail: 'Fail to create item',
      createdDraftSuccess: 'Created draft successfully',
      removedFromFavorite: 'Removed from favorite',
      addedToFavorite: 'Added to favorite',
      addedToHotBrands: 'Added to popular brands',
      updatedHotBrands: 'Updated popular brands',
      signOutSuccess: 'Sign Out Successfully!',
      uploadSuccess: 'Upload successfully',
    },
  },
  zh: {
    step: (obj: { step: number }) => `第 ${obj.step} 步`,
    signIn: '登入',
    signUp: '註冊',
    email: '電郵',
    password: '密碼',
    confirm: '確認',
    forgetPassword: '忘記密碼',
    backToSignIn: '返回登入頁面',
    resetPassword: '重設密碼',
    home: '主頁',
    seller: '私人賣家',
    members: '會員',
    proSeller: '專業賣家',
    products: '產品',
    items: '交易品',
    pushNotifications: '推送通知',
    proMembersRequests: '專業經銷商',
    messages: '訊息',
    users: '系統用戶',
    business: '公司資料',
    signOut: '登出',
    create: '新增',
    role: '會員類型',
    name: '名稱',
    mobile: '電話號碼',
    noRecords: '沒有紀錄',
    brands: '品牌',
    collections: '系列',
    itemTypes: '交易品類型',
    watch: '手錶',
    accessory: '配件',
    bag: '手袋',
    diamond: '鑽石',
    back: '返回',
    nameEn: '英文名稱',
    nameChi: '中文名稱',
    createCollection: '新增系列',
    edit: '修改',
    cancel: '取消',
    noCollections: '沒有系列',
    remove: '刪除',
    basicInfo: '基本資料',
    memberType: '會員種類',
    country: '國家',
    sellingItems: '出售交易品',
    viewAll: '檢視所有',
    banner: '公告橫幅',
    landing: '主頁',
    createBanner: '新增公告橫幅',
    action: '動作',
    goToItemType: '連結到交易品類型',
    goToItem: '連結到交易品',
    goToNews: '連結到消息',
    goToExternal: '外部連結',
    fileUploadPlaceholder:
      '把檔案拖到此處上戴 或者 <span class="filepond--label-action">按此上戴</span>',
    news: '消息',
    externalLink: '外部連結',
    createUser: '新增系統用戶',
    confirmToRemove: '確認刪除？',
    title: '標題',
    createdAt: '新增時間',
    updatedAt: '更新時間',
    createNews: '新增消息',
    content: '內容',
    saved: '已儲存！',
    saveInstruction: '按 CMD + S 儲存',
    image: '圖片',
    signInIsNeeded: '需要登入',
    goToSignIn: '前往登入頁面',
    signInWithEmail: '使用電郵登入',
    signInWithMobile: '使用電話號碼登入',
    signInMessage: '立即登入帳號，搜尋世界各地名錶。',
    signUpHint: `我們會致電或傳送簡訊，好確認您的電話號碼。這可能會產生一般簡訊和數據傳輸費用。`,
    continue: '繼續',
    selectYourMembershipType: '選擇您的會員類型',
    selectYourMembershipTypeHint: '根據您的身份選擇相應的會員類別',
    inputYourData: '輸入您的資料',
    confirmPassword: '確認密碼',
    inputYourDataHint: '選擇下方的同意並繼續，即表示我同意Amoeba的',
    tAndC: '服務條款',
    and: '及',
    privacyPolicy: '隱私政策',
    agreeAndContinue: '同意並繼續',
    error: '錯誤',
    resendOtp: '重新發送一次性驗證碼',
    verify: '驗證',
    welcomeMsg: '歡迎使用Amoeba！',
    signUpSuccessMsg: '註冊成功！立即搜尋世界各地名錶。',
    noAccountYet: '還沒有帳號嗎？',
    chooseTheItemType: '選擇交易品類別',
    nextStep: '下一步',
    selectBrand: '選擇品牌',
    loadingMore: '正在載入更多',
    loadedAll: '已經全部載入',
    publish: '發佈',
    saveAsDraft: '儲存為草稿',
    watchInfo: '手錶簡介',
    bagInfo: '手袋簡介',
    diamondInfo: '鑽石簡介',
    accessoryInfo: '配件簡介',
    itemName: '交易品名稱',
    addTag: '新增標記',
    uploadImage: '上傳圖片',
    description: '描述',
    itemDescription: '交易品描述',
    createNewTag: '新增標記',
    yes: '是',
    no: '否',
    price: '價格',
    confirmToRemoveBanner: '確認刪除公告橫幅？',
    itemsTypeData: '交易品資料',
    adNo: '廣告編號',
    status: '狀態',
    tags: '標記',
    views: '瀏覽量',
    watchBand: '錶帶',
    watchAccessory: '手錶附屬品',
    watchBandCellNo: '錶帶格數',
    watchBandMaterial: '錶布材質',
    watchBandTexture: '錶布材質',
    watchCertLocation: '出世卡地點',
    watchManufactureYear: '製造年份',
    watchMovement: '手錶機芯',
    watchStatus: '手錶狀態',
    bagColor: '手袋顏色',
    bagSize: '手袋尺寸',
    bagStatus: '手袋狀態',
    hasBagCert: '證書',
    diamondCategory: '鑽石類別',
    diamondPurity: '鑽石純度',
    diamondShape: '鑽石形狀',
    diamondStatus: '鑽石狀態',
    hasDiamondCert: '證書',
    accessoryStatus: '配件狀態',
    accessoryType: '配件類型',
    all: '全部',
    hotBrands: '熱門品牌',
    hotWatch: '熱門手錶',
    hotBag: '熱門手袋',
    hotDiamond: '熱門鑽石',
    allHotItems: '所有熱門',
    latestWatch: '最新手錶',
    latestBag: '最新手袋',
    latestDiamond: '最新鑽石',
    allItems: '所有交易品',
    allAccessory: '所有配件',
    yourFav: '尋找最愛',
    apply: '套用',
    search: '搜尋',
    sell: '出售',
    comments: '評論',
    filter: '篩選',
    sortBy: '排序',
    publicData: '公開資料',
    privateData: '私人資料',
    contactEmail: '聯絡電郵',
    contactMobile: '聯絡電話',
    setting: '設定',
    account: '帳號',
    changePassword: '修改密碼',
    preference: '偏好設定',
    notiSetting: '通知設定',
    about: '關於',
    contactUs: '聯絡我們',
    language: '語言',
    tc: '繁體中文',
    sc: '簡體中文',
    en: '英文',
    editProfile: '編輯個人資料',
    finish: '完成',
    surname: '姓',
    givenName: '名',
    bid: '出價',
    buy: '購買',
    allConversation: '所有對話',
    contactBuyer: '聯絡買家',
    bidHint: '輸入出價後，系統即會發送訊息給賣家。',
    accept: '接受',
    reject: '拒絕',
    accepted: '已接受',
    rejected: '已拒絕',
    bided: '已出價',
    closeConversation: '關閉對話',
    viewUserProfile: '查看用戶資料',
    closed: '已關閉',
    acceptBid: '接受出價',
    detail: '詳情',
    comment: '評論',
    commented: '已評論',
    commentDetail: '評論詳情',
    itemStatus: '交易品狀態',
    responseSpeed: '回應速度',
    serviceAttitude: '服務態度',
    trendingProcess: '交易流程',
    sold: '已出售',
    onHold: '已預留',
    trend: '趨勢',
    createTrend: '新增趨勢',
    year: '年',
    month: '月',
    basic: '基本資料',
    greeting: '歡迎使用Amoeba！',
    askToJoinMsg: '您需要登入才能探索奢華世界',
    valueTrend: '價值趨勢',
    sellerInformation: '賣家資訊',
    emptyTrend: '暫無趨勢',
    becomeVerifiedPro: '成為專業營運商',
    uploadProProveHint:
      '上傳「商業登記證」，請確保證件上的資料顯示能夠清楚顯示。',
    uploadProProveDoneMsg:
      '您已成功上傳「商業登記證」，當成功驗證後您將會收到系統通知。',
    history: '購買記錄',
    sms: 'SMS',
    optIn: '同意接受通知',
    favorite: '我的收藏',
    allNews: '所有消息',
    sellAnItem: '出售交易品',
    hotItems: '熱門交易品',
    latestItems: '最新交易品',
    allVariants: '所有款色',
    latestNews: '最新消息',
    noFavorite: '暫無收藏',
    resetPw: '重設密碼',
    resetPwHint: '請輸入您之前使用的電話號碼或電子郵件',
    searchOnAmoeba: '在Amoeba 28上搜尋',
    signUpWithEmail: '使用電子郵件註冊',
    signUpWithMobile: '使用手機號碼註冊',
    selectYourMembershipTypeHintSeller: '我以個人名義售賣收藏鐘錶',
    selectYourMembershipTypeHintProSeller: '我的職業是從事鐘錶買賣行業',
    waitForVerify: '等待驗證',
    conversations: '對話',
    roleStr: {
      member: '私人賣家',
      pro: '專業賣家',
      'pro-verified': '專業經銷商',
    },
    pagination: {
      showing: '顯示',
      to: '至',
      of: '共',
      results: '結果',
      previous: '上一頁',
      next: '下一頁',
    },
    errors: {
      EMAIL_IS_REQUIRED: '電郵是必須填寫的。',
      EMAIL_VALIDATE_ERROR: '這不是正確的電郵。',
      PASSWORD_IS_REQUIRED: '密碼是必須填寫的，必須是由英文數字組成。',
      PAGE_NUM_ERROR: '頁數不可少於1',
      ORDER_BY_ERROR: '排序出錯',
      WHERE_ERROR: '條件出錯',
      THIS_FIELD_IS_REQUIRED: '必須填寫這欄目',
      MOBILE_IS_USED: '電話號碼已經被使用',
      EMAIL_IS_USED: '電郵已經被使用',
      UNABLE_TO_SEND_CODE: '無法發送驗證碼',
      FAIL_TO_VERIFY_CODE: '無法驗證驗證碼',
      NOT_EXISTS_USER: '無此用戶',
      PW_NOT_CORRECT: '密碼不正確',
      TAG_ALREADY_EXISTS: '標記已經存在',
      MOBILE_VALIDATE_ERROR: '這不是正確的電話',
    },
    alert: {
      signInSuccess: '登入成功',
      signInFail: '登入失敗。請重試',
      updateSuccessfully: '成功更新',
      createUserFail: '新增失敗',
      removeSuccess: '成功刪除',
      removeFail: '刪除失敗。請重試',
      passwordNotMatch: '密碼不符',
      signUpFail: '註冊失敗，請重試',
      createdItemSuccess: '成功新增交易品',
      createdItemFail: '新增交易品失敗',
      createdDraftSuccess: '成功儲存草稿',
      removedFromFavorite: '成功從收藏中移除',
      addedToFavorite: '成功加入收藏',
      addedToHotBrands: '成功加入熱門品牌',
      updatedHotBrands: '成功更新熱門品牌',
      signOutSuccess: '成功登出',
      uploadSuccess: '成功上傳',
    },
  },
};

const i18n = rosetta(tables);

export default function useT() {
  const { locale } = useRouter() as { locale: string };

  const t = (key: string | (string | number)[], params?: any) => {
    if (locale === 'cn') {
      return sify(i18n.t(key, params, 'zh'));
    }
    return i18n.t(key, params, locale);
  };

  return { t, locale, i18n };
}
